import { Button } from "native-base";

interface BulkActionButtonProps {
  onPress: () => void;
  isDisabled?: boolean;
}

export default function BulkActionButton({
  onPress,
  isDisabled,
}: BulkActionButtonProps) {
  return (
    <Button onPress={onPress} w="150" mb={4} size="sm" isDisabled={isDisabled}>
      Aktion ausführen
    </Button>
  );
}
