import { CrackListItem } from "../components/web/protocol/area-to-image-table.helper";

export const isOriginalForCrackId = (crackId: string) => (crack: any) => {
  const { crackType } = crack.data();

  return crackType === "original" && crackId === crack.id;
};

export const isOriginalForCrackListItem =
  (crackListItem: CrackListItem) => (cli: CrackListItem) => {
    const { id, crackType } = cli.crack || {};

    // Info: the isNote check is there to deal with notes disguised as crack list items
    // "crackListItems": [
    //   {
    //     "areaTitle": "Hauptgebäude",
    //     "note": "Keine essenzielle Risse\nKeine essenzielle Risse ersichtlich\nKeine essenzielle Risse ersichtlich"
    //   }
    // ],

    return (
      (crackType === "original" && crackListItem.crack?.id === id) ||
      (crackListItem.isNote &&
        cli.isNote &&
        cli.areaTitle === crackListItem.areaTitle &&
        cli.note === crackListItem.note)
    );
  };

export const isFollowUpForCrackId = (crackId: string) => (crack: any) => {
  const { crackType, mainCrackId } = crack.data();

  return (
    crackType === "followUp" &&
    (crackId === crack.id || crackId === mainCrackId)
  );
};

export const isFollowUpForCrackListItem =
  (crackListItem: CrackListItem) => (cli: CrackListItem) => {
    const { id, crackType, mainCrackId } = cli.crack || {};

    return (
      crackType === "followUp" &&
      (crackListItem.crack?.id === id ||
        crackListItem.crack?.id === mainCrackId)
    );
  };

export const isNoVisibleChange = (crack: any) => {
  const { crackType, noVisibleChange } = crack.data();

  return crackType === "followUp" && noVisibleChange;
};
