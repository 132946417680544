import { parseJSON } from "date-fns";
import { ScrollView } from "native-base";
import React from "react";
import { formatDate } from "../../../helper/date-helper";
import { thumbnailOrOriginalUrl } from "../../../helper/thumbnail-helper";
import { Protocol } from "../WebAccess";
import ArchiveDownload from "./ArchiveDownload";
import { areaToImageTableHelper } from "./area-to-image-table.helper";
import TenantLogo from "./TenantLogo";

type Props = {
  protocol: Protocol;
};

export default function WebProtocol({ protocol }: Props) {
  const {
    title,
    constructionSite,
    projectNumber,
    client,
    protocolAuthor,
    owner,
    captureDate,
    overviewPhotos,
    overviewMaps,
    areas,
    totalPhotoCount,
    objectType,
    protocolPDFUrl,
    isFollowUpInspection,
    tenant,
  } = protocol;

  const formattedTotalPhotoCount = totalPhotoCount.toLocaleString("de-CH");

  const showOverviewMap = overviewMaps && overviewMaps[0];

  return (
    <ScrollView height="100vh">
      <div
        className="webprotocol"
        style={
          {
            "--primary-color": tenant?.primaryColor,
          } as React.CSSProperties
        }
      >
        <TenantLogo tenant={tenant} />

        <h1>Rissprotokoll</h1>

        <div className="overview-photos">
          {overviewPhotos.map((photo) => (
            <div key={photo.url} className="overview-photo">
              <a href={photo.url} target="_blank">
                <img src={thumbnailOrOriginalUrl(photo)} />
              </a>
            </div>
          ))}
        </div>

        <h3>Objekt</h3>
        {title}

        <h3>Projektnummer</h3>
        {projectNumber}

        <h3>Aufnahmedatum</h3>
        {formatDate(parseJSON(captureDate ?? ""))}

        <h3>Eigentümer</h3>
        <div className="with-line-breaks">{owner}</div>

        <h3>Auftraggeber</h3>
        <div className="with-line-breaks">{client}</div>

        <h3>Berichtverfasser</h3>
        <div className="with-line-breaks">{protocolAuthor}</div>

        <h2>Informationen</h2>

        <h3>Allgemeine Bedingungen</h3>
        <p>
          {objectType === "street"
            ? "Aufgrund der Baumassnahmen werden Vibrationen, Setzungen etc. vermutet. Deshalb wird vor Baubeginn der Zustand des Nachbargebäudes schriftlich und fotografisch festgehalten. Die Aufnahme erfolgt gemäss der Schweizer Norm VSS-40312, Erschütterungen, Erschütterungseinwirkungen auf Bauwerke."
            : "Aufgrund der Baumassnahmen werden Vibrationen, Setzungen etc. vermutet. Deshalb wird vor Baubeginn der Zustand des Nachbargebäudes schriftlich und fotografisch festgehalten. Es werden die Risse, Abplatzungen und Verfärbungen erfasst, die ab 1 Meter von blossem Auge sichtbar sind. Die Besichtigung umfasst die beschriebenen Stockwerke und Aussenansichten. Die Aufnahme erfolgt gemäss der Schweizer Norm VSS-40312, Erschütterungen, Erschütterungseinwirkungen auf Bauwerke."}
        </p>
        <p>Die Norm schlägt folgende Beschreibungen vor:</p>
        <table>
          <thead>
            <tr>
              <th>Riss</th>
              <th>Breiten</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Feine Risse</td>
              <td>bis 0.2 mm</td>
            </tr>
            <tr>
              <td>Mittlere Risse</td>
              <td>0.2 bis 1.0 mm</td>
            </tr>
            <tr>
              <td>Breite Risse</td>
              <td>1.0 bis 3.0 mm</td>
            </tr>
            <tr>
              <td>Klaffende Risse</td>
              <td>ab 3.0 mm</td>
            </tr>
          </tbody>
        </table>

        <p>Die Norm erachtet folgende Rissbreiten als zulässig:</p>
        <table>
          <thead>
            <tr>
              <th>Beschreibung</th>
              <th>Breiten</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hohe ästhetische Anforderung</td>
              <td>bis 0.1 mm</td>
            </tr>
            <tr>
              <td>Aussenbauteile umweltbelastet</td>
              <td>bis 0.2 mm</td>
            </tr>
            <tr>
              <td>Aussenbauteile nicht umweltbelastet</td>
              <td>bis 0.3 mm</td>
            </tr>
            <tr>
              <td>Innenbauteile</td>
              <td>bis 0.4 mm</td>
            </tr>
          </tbody>
        </table>

        <h2>Abgrenzungen</h2>
        <p>
          {objectType === "street"
            ? "Verdeckte und nicht sichtbare Schäden hinter Gebüschen, parkierten Autos etc. werden nicht erfasst. Unterirdische Konstruktionen wie Leitungen oder Fluchtröhren werden ebenfalls nicht dokumentiert."
            : "Verdeckte und nicht sichtbare Schäden hinter Gebüschen, Fensterläden, Verkleidungen, Bilder, Möbel etc. werden nicht erfasst. Wo die Fassaden nicht über einen Balkon näher betrachtet werden können, erfolgt die Dokumentation vom Boden aus. Vorplätze und Kunstbauten werden erfasst, wenn sie einen direkten Zusammenhang mit dem aufzunehmenden Gebäude haben. Freistehende Mauern oder Bauwerke sowie unterirdische Konstruktionen wie Leitungen oder Fluchtröhren werden nicht dokumentiert. Risse bei Materialübergängen sowie in Holz sind von der Dokumentation ausgeschlossen, da diese Erscheinungen von der Temperatur und der Luftfeuchtigkeit abhängen."}
        </p>

        {objectType !== "street" && (
          <>
            <h2>Abhängigkeiten</h2>
            <p>
              Für eine zuverlässige und vollständige Dokumentation muss uns der
              Zugang zu allen Räumen gewährt werden.
            </p>
          </>
        )}

        <h2>Informationen Baustelle</h2>
        <p>{`Standort / Adresse: ${constructionSite}`}</p>

        {showOverviewMap && (
          <>
            <h2>Bereich der Aufnahmen</h2>
            <div className="overview-maps">
              {overviewMaps.map((photo) => (
                <div key={photo.url} className="overview-map">
                  <a href={photo.url} target="_blank">
                    <img src={thumbnailOrOriginalUrl(photo)} />
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        <h2>Fotodokumentation</h2>

        {areas.flatMap((a, rootIndex) =>
          areaToImageTableHelper(
            a,
            rootIndex + 1,
            objectType,
            isFollowUpInspection
          )
        )}

        <p>{`Es wurden ca. ${formattedTotalPhotoCount} Fotos aufgenommen. Diese sind massgebend und werden bei einer Beurteilung von zusätzlichen Schäden, Rissen etc. beigezogen. Die Fotos werden fünf Jahre aufbewahrt.`}</p>

        <h2>Download</h2>

        <h3>Rissprotokoll</h3>
        <a href={protocolPDFUrl} download="Rissprotokoll.pdf" target="_blank">
          Rissprotokoll als PDF herunterladen
        </a>

        <h3>Fotos</h3>
        <ArchiveDownload protocol={protocol} />
      </div>
    </ScrollView>
  );
}
