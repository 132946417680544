import { useState } from "react";
import { Select } from "native-base";
import BulkActionButton from "./BulkActionButton";
import BulkActionRetourLetter from "./BulkActionRetourLetter";
import BulkActionFinalCoverLetter from "./BulkActionFinalCoverLetter";
import BulkActionCoverLetter from "./BulkActionCoverLetter";

const AVAILABLE_ACTIONS = [
  { value: "retour-letter", label: "Retourbriefe generieren" },
  { value: "cover-letter", label: "Begleitschreiben Eigentümer generieren" },
  {
    value: "final-cover-letter",
    label: "Begleitschreiben Schlussexemplar generieren",
  },
];

type Action = (typeof AVAILABLE_ACTIONS)[number]["value"];

interface ObjectBulkActionsProps {
  projectId: string;
  objectIds: string[];
}

export default function ObjectBulkActions({
  projectId,
  objectIds,
}: ObjectBulkActionsProps) {
  const [action, setAction] = useState<Action | "">("");

  return (
    <>
      <Select
        size="md"
        placeholder="Mehrfachaktion auswählen..."
        selectedValue={action}
        onValueChange={setAction}
        width="300"
        mr={4}
      >
        {AVAILABLE_ACTIONS.map(({ value, label }) => (
          <Select.Item key={value} label={label} value={value} />
        ))}
      </Select>

      {!action && (
        <BulkActionButton
          onPress={() => {}}
          isDisabled={!action || objectIds.length === 0}
        />
      )}

      {action === "retour-letter" && (
        <BulkActionRetourLetter projectId={projectId} objectIds={objectIds} />
      )}

      {action === "cover-letter" && (
        <BulkActionCoverLetter projectId={projectId} objectIds={objectIds} />
      )}

      {action === "final-cover-letter" && (
        <BulkActionFinalCoverLetter
          projectId={projectId}
          objectIds={objectIds}
        />
      )}
    </>
  );
}
