import { Button } from "native-base";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCreateRetourLetter } from "../../../hooks/useCreateRetourLetter";

type Props = {
  object: DocumentSnapshot<DocumentData>;
};

export default function CreateRetourLetterButton({ object }: Props) {
  const [createRetourLetter, isLoading] = useCreateRetourLetter();

  return (
    <Button
      onPress={() => createRetourLetter(object)}
      variant="solid"
      isLoading={isLoading}
    >
      Retourbrief
    </Button>
  );
}
