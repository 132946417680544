import React, { useCallback } from "react";
import { getDoc } from "firebase/firestore";
import { useCreateRetourLetter } from "../../../hooks/useCreateRetourLetter";
import BulkActionButton from "./BulkActionButton";
import { useFirestore } from "../../../hooks/useFirestore.web";

interface BulkActionRetourLetterProps {
  projectId: string;
  objectIds: string[];
}

export default function BulkActionRetourLetter({
  projectId,
  objectIds,
}: BulkActionRetourLetterProps) {
  const [_tenantId, _store, doc] = useFirestore();
  const [createRetourLetter, _isLoading, isReady] = useCreateRetourLetter();

  const performAction = useCallback(async () => {
    for (const objectId of objectIds) {
      const docPath = ["projects", projectId, "objects", objectId].join("/");
      const object = await getDoc(doc(docPath));
      await createRetourLetter(object);
    }
  }, [createRetourLetter, projectId, objectIds, doc]);

  return (
    <BulkActionButton
      onPress={performAction}
      isDisabled={!isReady || objectIds.length === 0}
    />
  );
}
