import { Button } from "native-base";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCreateCoverLetter } from "../../../hooks/useCreateCoverLetter";

type Props = {
  object: DocumentSnapshot<DocumentData>;
};

export default function CreateCoverLetterButton({ object }: Props) {
  const [createCoverLetter, isLoading] = useCreateCoverLetter();

  return (
    <Button
      onPress={() => createCoverLetter(object)}
      variant="solid"
      isLoading={isLoading}
    >
      Begleitschreiben Eigentümer
    </Button>
  );
}
