import { useCallback } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCreateLetter } from "./useCreateLetter";

export const useCreateRetourLetter = () => {
  const [createLetter, isLoading, isReady] = useCreateLetter("retour");

  const createRetourLetter = useCallback(
    (object: DocumentSnapshot<DocumentData>) => {
      const protocolAuthor = object?.get("protocolAuthor") ?? "";

      const payload = {
        protocolAuthor,
      };

      return createLetter(payload);
    },
    [createLetter]
  );

  return [createRetourLetter, isLoading, isReady] as const;
};
