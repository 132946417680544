import { useCallback } from "react";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCreateLetter } from "./useCreateLetter";
import { useAuthorFields } from "./useAuthorFields";
import { useCurrentUser } from "./useCurrentUser";
import { DEFAULT_SALUTATION } from "../components/web/components/SalutationField";

export const useCreateFinalCoverLetter = () => {
  const [user] = useCurrentUser();
  const [createLetter, isLoading, isReady] = useCreateLetter("finalCover");
  const author = useAuthorFields(user);

  const createFinalCoverLetter = useCallback(
    (object: DocumentSnapshot<DocumentData>) => {
      const address = object.get("owner") ?? "";
      const salutation = object.get("salutation") ?? DEFAULT_SALUTATION;
      const objectAddress = object.get("address") ?? "";

      const payload = {
        address,
        salutation,
        author,
        objectAddress,
      };

      return createLetter(payload);
    },
    [createLetter, author]
  );

  return [createFinalCoverLetter, isLoading, isReady] as const;
};
