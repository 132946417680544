import React from "react";
import { useParams } from "react-router-dom";
import { Spinner, Stack } from "native-base";
import Headline from "../typo/Headline";
import TextFormInput from "../form/TextFormInput";
import TextAreaFormInput from "../form/TextAreaFormInput.web";
import { useProject } from "../../hooks/useProject";
import ProjectDetailState from "../projects/ProjectDetailState";
import CreateObjectButton from "../projects/CreateObjectButton.web";
import DeleteProjectButton from "../projects/DeleteProjectButton.web";
import Spacer from "./components/Spacer";
import TwoCols from "./shared/TwoCols";
import ProjectWebAccess from "../projects/ProjectWebAccess";
import SalutationField from "./components/SalutationField";
import CreateProjectCoverLetterButton from "../object/protocol/CreateProjectCoverLetterButton";
import ProjectObjects from "./ProjectObjects";

export default function Project() {
  const { projectId } = useParams();

  const { project, updateProject, isLoading } = useProject(projectId ?? "");

  return (
    <Stack px={5} py={6}>
      <Headline title="Auftrag" />

      {isLoading ? (
        <Spinner />
      ) : (
        <Stack>
          <TwoCols
            leftContent={
              <>
                <TextFormInput
                  label="Projektnummer"
                  fieldPath="projectNumber"
                  doc={project as any}
                  updateDoc={updateProject as any}
                />

                <TextFormInput
                  label="Projektname"
                  fieldPath="title"
                  doc={project as any}
                  updateDoc={updateProject as any}
                />

                <TextAreaFormInput
                  label="Bemerkungen"
                  fieldPath="notes"
                  doc={project as any}
                  updateDoc={updateProject as any}
                />
              </>
            }
            rightContent={
              <>
                <TextFormInput
                  label="Baustelle"
                  fieldPath="constructionSite"
                  doc={project as any}
                  updateDoc={updateProject as any}
                />

                <TextAreaFormInput
                  label="Auftraggeber"
                  fieldPath="client"
                  doc={project as any}
                  updateDoc={updateProject as any}
                />

                <SalutationField
                  object={project as any}
                  updateObject={updateProject as any}
                />

                <CreateProjectCoverLetterButton project={project as any} />
              </>
            }
          />

          <ProjectDetailState
            project={project as any}
            updateProject={updateProject as any}
          />
        </Stack>
      )}

      <Spacer />

      <ProjectObjects projectId={projectId ?? ""} />

      {project && <CreateObjectButton project={project as any} />}

      {/*<Spacer />

      <Headline title="Termine" />
      */}

      <Spacer />

      <Headline title="Webprotokoll" />

      {project && (
        <ProjectWebAccess
          project={project as any}
          updateProject={updateProject as any}
        />
      )}

      <DeleteProjectButton project={project as any} />
    </Stack>
  );
}
