import React, { useCallback, useMemo, useState } from "react";
import { Button, Checkbox, HStack, Spinner, Stack } from "native-base";
import Headline from "../typo/Headline";
import { useObjects } from "../../hooks/useObjects";
import ObjectSnippet from "./components/ObjectSnippet";
import ObjectBulkActions from "./protocol/ObjectBulkActions";

interface ProjectObjectsProps {
  projectId: string;
}

export default function ProjectObjects({ projectId }: ProjectObjectsProps) {
  const [objects, isLoadingObjects] = useObjects(projectId);

  const objectIds = useMemo(() => objects.map((o) => o.id), [objects]);

  const [selectedObjectIds, setSelectedObjectIds] = useState<string[]>([]);

  const allChecked = useMemo(
    () => selectedObjectIds.length === objectIds.length,
    [selectedObjectIds, objectIds]
  );

  const toggleSelectAll = useCallback(() => {
    setSelectedObjectIds(allChecked ? [] : objectIds);
  }, [allChecked, objectIds]);

  return (
    <>
      <Headline title="Objekte" />

      <Checkbox.Group onChange={setSelectedObjectIds} value={selectedObjectIds}>
        {isLoadingObjects ? (
          <Spinner />
        ) : (
          <Stack>
            {objects.map((o) => (
              <ObjectSnippet key={o.id} object={o as any} />
            ))}
          </Stack>
        )}
      </Checkbox.Group>

      <HStack space={4}>
        <Button
          onPress={toggleSelectAll}
          w="150"
          mb={4}
          variant="outline"
          size="sm"
        >
          {allChecked ? "Alle abwählen" : "Alle auswählen"}
        </Button>

        <ObjectBulkActions
          projectId={projectId}
          objectIds={selectedObjectIds}
        />
      </HStack>
    </>
  );
}
