import React, { useCallback } from "react";
import { getDoc } from "firebase/firestore";
import { useCreateCoverLetter } from "../../../hooks/useCreateCoverLetter";
import BulkActionButton from "./BulkActionButton";
import { useFirestore } from "../../../hooks/useFirestore.web";

interface BulkActionCoverLetterProps {
  projectId: string;
  objectIds: string[];
}

export default function BulkActionCoverLetter({
  projectId,
  objectIds,
}: BulkActionCoverLetterProps) {
  const [_tenantId, _store, doc] = useFirestore();
  const [createCoverLetter, _isLoading, isReady] = useCreateCoverLetter();

  const performAction = useCallback(async () => {
    for (const objectId of objectIds) {
      const docPath = ["projects", projectId, "objects", objectId].join("/");
      const object = await getDoc(doc(docPath));
      await createCoverLetter(object);
    }
  }, [createCoverLetter, projectId, objectIds, doc]);

  return (
    <BulkActionButton
      onPress={performAction}
      isDisabled={!isReady || objectIds.length === 0}
    />
  );
}
