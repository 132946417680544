import React from "react";
import { Link } from "react-router-dom";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { Stack, HStack, Text, Box, Checkbox } from "native-base";
import ObjectThumbnail from "../../object/ObjectThumbnail";
import ObjectState from "../../object/ObjectState";

type Props = {
  object: QueryDocumentSnapshot<DocumentData>;
};

export default function ObjectSnippet({ object }: Props) {
  const { id } = object;
  const { title } = object.data();

  return (
    <HStack space={4} mb={4}>
      <Checkbox value={id} />

      <Box w="120px">
        <Link to={id}>
          <ObjectThumbnail object={object as any} />
        </Link>
      </Box>

      <Link to={id}>
        <Stack>
          <Text fontSize="lg" fontWeight="bold">
            {title}
          </Text>

          <ObjectState object={object as any} />
        </Stack>
      </Link>
    </HStack>
  );
}
