import { useCallback, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import { useCurrentTenant } from "./useCurrentTenant";
import { useBranchForUser } from "./useBranchForUser";
import { getBackendURL } from "../environment";

const filenameMapping = {
  appointment: "Terminbrief.docx",
  retour: "Retourbrief.docx",
  cover: "Begleitschreiben.docx",
  projectCover: "Begleitschreiben - Auftraggeber.docx",
  finalCover: "Begleitschreiben - Schlussexamplar.docx",
};

interface Branch {
  name: string;
  street: string;
  zipCode: string;
  city: string;
}

interface Tenant {
  name: string;
  primaryColor: string;
  logoImageUrl: string;
  website: string;
}

export const useCreateLetter = (
  letterType: "appointment" | "retour" | "cover" | "projectCover" | "finalCover"
) => {
  const [isLoading, setIsLoading] = useState(false);

  const tenant = useCurrentTenant();
  const [branch] = useBranchForUser();

  const tenantMetaData = useMemo(() => {
    const { name, primaryColor, logoImageUrl, website } = tenant?.data() || {};

    return {
      name: name ?? "",
      primaryColor: primaryColor ?? "",
      logoImageUrl: logoImageUrl ?? "",
      website: website ?? "",
    } as Tenant;
  }, [tenant]);

  const branchMetaData = useMemo(() => {
    const { name, street, zipCode, city } = branch?.data() || {};

    return {
      name: name ?? "",
      street: street ?? "",
      zipCode: zipCode ?? "",
      city: city ?? "",
    } as Branch;
  }, [branch]);

  const createLetter = useCallback(
    async (payload: any) => {
      setIsLoading(true);

      const response = await fetch(`${getBackendURL()}/letter/${letterType}`, {
        method: "POST",
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tenant: tenantMetaData,
          branch: branchMetaData,
          ...payload,
        }),
      });

      const blob = await response.blob();

      saveAs(blob, filenameMapping[letterType]);
      setIsLoading(false);
    },
    [setIsLoading, tenantMetaData, branchMetaData, letterType]
  );

  const isReady = !!tenant && !!branch;

  return [createLetter, isLoading, isReady] as const;
};
