import React, { useCallback } from "react";
import { getDoc } from "firebase/firestore";
import { useCreateFinalCoverLetter } from "../../../hooks/useCreateFinalCoverLetter";
import BulkActionButton from "./BulkActionButton";
import { useFirestore } from "../../../hooks/useFirestore.web";

interface BulkActionFinalCoverLetterProps {
  projectId: string;
  objectIds: string[];
}

export default function BulkActionFinalCoverLetter({
  projectId,
  objectIds,
}: BulkActionFinalCoverLetterProps) {
  const [_tenantId, _store, doc] = useFirestore();
  const [createFinalCoverLetter, _isLoading, isReady] =
    useCreateFinalCoverLetter();

  const performAction = useCallback(async () => {
    for (const objectId of objectIds) {
      const docPath = ["projects", projectId, "objects", objectId].join("/");
      const object = await getDoc(doc(docPath));
      await createFinalCoverLetter(object);
    }
  }, [createFinalCoverLetter, projectId, objectIds, doc]);

  return (
    <BulkActionButton
      onPress={performAction}
      isDisabled={!isReady || objectIds.length === 0}
    />
  );
}
