import { Button } from "native-base";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { useCreateFinalCoverLetter } from "../../../hooks/useCreateFinalCoverLetter";

type Props = {
  object: DocumentSnapshot<DocumentData>;
};

export default function CreateFinalCoverLetterButton({ object }: Props) {
  const [createFinalCoverLetter, isLoading] = useCreateFinalCoverLetter();

  return (
    <Button
      onPress={() => createFinalCoverLetter(object)}
      variant="solid"
      isLoading={isLoading}
    >
      Begleitschreiben Schlussexamplar
    </Button>
  );
}
